<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'รายการบัญชี' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <vx-card title="รายการตรวจสอบบัญชี">
    <vs-table :data="users">
      <template slot="thead">
        <vs-th>
          ลำดับ
        </vs-th>
        <vs-th>
          รายการ
        </vs-th>
        <vs-th>
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>

          <vs-td :data="data[indextr].name">
            {{data[indextr].name}}
          </vs-td>

          <vs-td :data="data[indextr].page">
            <router-link :to="`BankCompare/${data[indextr].page}`">
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-list" class="mr-2"
                  >เข้าชมรายการ</vs-button>
              </router-link>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
      </div>
    </vs-tab>
    <vs-tab v-if="$store.state.AppActiveUser.permissions.audit.action" icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'อัพโหลด KBANK Statement' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <bankkbank />
      </div>
    </vs-tab>
  </vs-tabs>


</template>

<script>
import Bankkbank from './Bankkbank.vue'

export default {
  components: {
    Bankkbank
  },
  data () {
    return {
      users: [
        {
          'id': 1,
          'name': 'รายการรวม',
          'page' : 'list'
        },
        {
          'id': 2,
          'name': 'รายการผิดปกติ',
          'page' : 'listerror'
        }
      ]
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
